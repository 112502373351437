import { composeSDKFactories } from '@wix/editor-elements-corvid-utils';
import { createComponentSDKModel } from '@wix/editor-elements-integrations';
import { CorvidTypes } from '@wix/editor-elements-types';
import {
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  createStylePropsSDKFactory,
  createElementPropsSDKFactory,
  toJSONBase,
} from '../../../core/corvid/props-factories';
import {
  IFormContainerProps,
  IFormContainerSdkProps,
} from '../FormContainer.types';

export const stylePropsSDKFactory = createStylePropsSDKFactory({
  BackgroundColor: true,
  BorderColor: true,
  BorderWidth: true,
  BorderRadius: true,
});

export const formContainerSDKFactory: CorvidTypes.CorvidSDKFactory<any> = api => ({
  get type() {
    return '$w.Form';
  },
  get isfp() {
    /*
      This getter name is unclear (well it means 'is fake person') by design to mask the real purpose from malicious users.
      This represents the honeypot input value of the form container which should be empty for humans and filled for bots
    */
    return api.props.honeypotValue;
  },
  toJSON() {
    return {
      ...toJSONBase(api.metaData),
      type: '$w.Form',
    };
  },
});

const elementPropsSDKFactory = createElementPropsSDKFactory();

export const sdk = composeSDKFactories<
  IFormContainerProps,
  IFormContainerSdkProps
>(
  elementPropsSDKFactory,
  childrenPropsSDKFactory,
  clickPropsSDKFactory,
  stylePropsSDKFactory,
  formContainerSDKFactory,
);

export default createComponentSDKModel(sdk);
